import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 480.000000 467.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,467.000000) scale(0.100000,-0.100000)">
<path d="M2217 3469 c-305 -85 -544 -330 -614 -629 -24 -104 -24 -102 27 -116
240 -63 249 -63 250 1 0 17 11 60 24 96 60 171 208 309 377 354 70 19 200 19
272 0 183 -48 345 -208 390 -386 9 -32 20 -55 29 -57 9 -2 37 11 63 29 26 17
77 48 113 68 48 26 68 43 70 59 4 24 -85 214 -115 247 -10 11 -40 46 -66 78
-106 125 -253 212 -445 263 -79 21 -289 17 -375 -7z"/>
<path d="M2279 3046 c-149 -40 -298 -207 -315 -352 l-7 -52 65 -36 c87 -48 98
-47 98 9 0 99 59 194 156 251 43 25 59 29 129 29 65 0 89 -5 127 -24 67 -34
125 -97 147 -161 22 -62 26 -63 116 -37 43 12 50 18 53 42 8 65 -100 218 -197
280 -99 62 -253 83 -372 51z m236 -11 c124 -32 231 -118 284 -228 45 -93 44
-97 -14 -118 -27 -9 -55 -18 -61 -18 -6 -1 -21 24 -33 54 -27 64 -84 125 -149
157 -39 19 -63 23 -137 23 -81 0 -95 -3 -144 -30 -87 -48 -142 -130 -156 -232
-4 -29 -9 -53 -11 -53 -16 0 -124 66 -124 76 0 7 8 38 18 71 70 222 306 356
527 298z"/>
<path d="M2324 2766 c-58 -27 -104 -80 -133 -155 -32 -79 -29 -97 17 -108 l39
-10 13 49 c31 123 158 177 235 100 31 -30 65 -93 65 -118 0 -13 7 -11 38 12
20 15 39 29 40 30 2 1 -9 30 -24 64 -43 96 -128 160 -214 160 -14 0 -48 -11
-76 -24z"/>
<path d="M2343 2491 c-62 -16 -147 -66 -200 -118 -97 -94 -145 -238 -125 -373
36 -242 267 -412 504 -372 166 29 293 137 348 297 27 79 25 101 -4 40 -44 -96
-137 -154 -247 -155 -108 0 -193 49 -244 141 -26 47 -30 64 -30 134 0 90 14
126 74 188 59 63 104 82 196 82 95 0 139 -18 203 -83 23 -24 42 -41 42 -37 0
3 -15 29 -33 58 -47 72 -117 133 -194 169 -57 27 -79 32 -162 34 -53 2 -111
-1 -128 -5z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
